export const LowerCase = new RegExp(/[a-z]/);
export const UpperCase = new RegExp(/[A-Z]/);
export const HasNumber = new RegExp(/[0-9]/);
export const HasSpecialCharacter = new RegExp(
  /[-+=!$%^&*()_|~`{}\[\]:\/;<>?,.@#'"]/
);
export const ClearWhiteSpaces = new RegExp(/^\s+|\s+$|\s+(?=\s)/);
export const MoreThan2WP = new RegExp(/\s*\s$|\s{2,}/);
export const StartEndWP = new RegExp(/\s{2,}/g);
export const AnyWP = new RegExp(/\s/);
export const GuidPattern = new RegExp(
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
);
export const PhoneNumberPattern = new RegExp(/^[1-9]\d{2}-\d{3}-\d{4}$/);
