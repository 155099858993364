import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { FSFormField } from './fs-form-field.model';
import { debounceTime, fromEvent, map, Subscription } from 'rxjs';

@Component({
  selector: 'fs-form-field',
  templateUrl: './fs-form-field.component.html',
  styleUrls: ['./fs-form-field.component.scss'],
})
export class FsFormFieldComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  @ViewChild('fsFormField') fsFormField: ElementRef;

  @Input('inputDelay') searchDelay: number;
  @Input('activeSearch') activeSearch: boolean;

  @Input('hideCounter') hideCounter: boolean;
  @Input('activeClickable') activeClickable: boolean;
  @Input('type') inputType: string;
  @Input('hideActions') hideActions: boolean;
  @Input('options') options: FSFormField;
  @Input('readOnly') readOnly: boolean;

  @Output('onSearch') onSearch = new EventEmitter<any>();
  @Output('onClearValue') onClearValue = new EventEmitter<void>();

  innerHtml: HTMLElement;
  innerControlEl: HTMLElement;
  inputEl: HTMLInputElement;
  textareaEl: HTMLTextAreaElement;
  toggleSwitchEl: HTMLElement;

  maxLength: number;
  innerText: string;

  get inputValue(): any {
    if (this.inputEl) return this.inputEl.value ?? '';
    else if (this.textareaEl) return this.textareaEl.value ?? '';
    else return '';
  }

  keyupSub$: Subscription;
  changeSub$: Subscription;
  focusSub$: Subscription;
  inputSub$: Subscription;
  searchSub$: Subscription;
  observer$: MutationObserver;

  constructor() {
    this.inputType = 'text';
    this.hideActions = this.activeClickable = this.readOnly = false;
    this.hideCounter = true;
    this.maxLength = 0;
    this.options = new FSFormField({});
    this.innerText = '';
    this.searchDelay = 500;
    this.activeSearch = false;
  }

  ngOnDestroy(): void {
    this.keyupSub$?.unsubscribe();
    this.changeSub$?.unsubscribe();
    this.focusSub$?.unsubscribe();
    this.searchSub$?.unsubscribe();
    this.inputSub$?.unsubscribe();
    this.observer$?.disconnect();
  }

  ngOnChanges(changes: any): void {
    if (changes) {
      if (changes['readOnly']) {
        if (this.readOnly) {
          this.deactiveInnerControls();
        } else {
          this.activeInnerControls();
        }
      }

      if (changes['activeSearch']) {
        if (!this.activeSearch) {
          this.searchSub$?.unsubscribe();
        } else {
          if (
            this.innerControlEl.tagName.toLowerCase() === 'input' ||
            this.innerControlEl.tagName.toLowerCase() === 'textarea'
          ) {
            this.appendSearchEvent(
              this.innerControlEl as HTMLInputElement | HTMLTextAreaElement
            );
          }
        }
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.innerHtml = this.fsFormField.nativeElement;
      this.innerControlEl = this.innerHtml.querySelector(
        '.fs-field-wrapper > *:last-child'
      );

      if (this.innerControlEl) {
        const tagName = this.innerControlEl.tagName.toLowerCase();

        if (tagName === 'input') {
          this.inputEl = this.innerControlEl as HTMLInputElement;

          if (this.inputEl.type === 'number') {
            this.innerHtml
              .querySelector('.fs-field-wrapper')
              ?.classList.add('fs-field-numeric');
          } else {
            this.innerHtml
              .querySelector('.fs-field-wrapper')
              ?.classList.add('fs-field-input');
          }

          this.appendMutation(this.inputEl);
          this.appendEvents(this.inputEl);
          this.appendSearchEvent(this.inputEl);

          setTimeout(() => {
            this.innerText = this.inputEl.value;
            this.maxLength = this.inputEl.maxLength;
          }, 150);
        } else if (tagName === 'textarea') {
          this.textareaEl = this.innerControlEl as HTMLTextAreaElement;

          this.hideActions = true;

          this.innerHtml
            .querySelector('.fs-field-wrapper')
            ?.classList.add('fs-field-input');

          this.appendMutation(this.textareaEl);
          this.appendEvents(this.textareaEl);
          this.appendSearchEvent(this.textareaEl);

          setTimeout(() => {
            this.innerText = this.textareaEl.value;
            this.maxLength = this.textareaEl.maxLength;
          }, 150);
        } else if (tagName === 'fs-toggle-switch') {
          this.toggleSwitchEl =
            this.innerHtml.querySelector('.fs-toggle-switch');

          this.innerHtml
            .querySelector('.fs-field-wrapper')
            .classList.add('fs-field-toggle-switch');
        }
      }

      if (this.readOnly) {
        this.deactiveInnerControls();
      }
    });
  }

  appendMutation(el: HTMLInputElement | HTMLTextAreaElement): void {
    this.observer$ = new MutationObserver(() => {
      this.innerText = el.value;
      this.maxLength = el.maxLength;
    });

    this.observer$.observe(el, {
      attributes: true,
      characterData: true,
    });
  }

  appendEvents(el: HTMLInputElement | HTMLTextAreaElement): void {
    this.changeSub$ = fromEvent(el, 'change')
      .pipe(
        map((e) => (e.target as HTMLInputElement | HTMLTextAreaElement)?.value)
      )
      .subscribe((e: any) => {
        this.innerText = e;
      });

    this.focusSub$ = fromEvent(el, 'focus')
      .pipe(
        map((e) => (e.target as HTMLInputElement | HTMLTextAreaElement)?.value)
      )
      .subscribe((e: any) => {
        this.innerText = e;
      });

    this.inputSub$ = fromEvent(el, 'input')
      .pipe(
        map((e) => (e.target as HTMLInputElement | HTMLTextAreaElement)?.value)
      )
      .subscribe((e: any) => {
        this.innerText = e;
      });
  }

  appendSearchEvent(el: HTMLInputElement | HTMLTextAreaElement): void {
    if (this.activeSearch) {
      this.searchSub$ = fromEvent(el, 'input')
        .pipe(debounceTime(this.searchDelay))
        .pipe(
          map(
            (e) => (e.target as HTMLInputElement | HTMLTextAreaElement)?.value
          )
        )
        .subscribe((e: any) => this.onSearch.emit(e));
    }
  }

  onClearText(): void {
    this.inputEl.value = this.innerText = '';
    this.innerControlEl.focus();
    this.onClearValue.emit();
  }

  onVisibilityPassword(): void {
    if (this.inputEl.type !== 'password') {
      this.inputEl.type = 'password';
    } else {
      this.inputEl.type = 'text';
    }
  }

  deactiveInnerControls(): void {
    this.innerHtml
      ?.querySelectorAll(
        '.action-field button, .fs-field-wrapper button, .fs-field-wrapper input, .fs-field-wrapper textarea'
      )
      ?.forEach((control: HTMLElement) => {
        control.tabIndex = -1;
      });
  }

  activeInnerControls(): void {
    this.innerHtml
      ?.querySelectorAll(
        '.action-field button, .fs-field-wrapper button, .fs-field-wrapper input, .fs-field-wrapper textarea'
      )
      ?.forEach((control: HTMLElement) => {
        control.removeAttribute('tabindex');
      });
  }

  onClickDate(): void {
    this.inputEl.focus();
    this.inputEl.showPicker();
  }
}
