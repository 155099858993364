import { DOMHelper } from './dom-helper';

export class Convert {
  public static toSignNumber = (value: number): string => {
    if (value > 0) return `+${value}`;
    else if (value < 0) return value.toString();
    else return value.toString();
  };

  public static toInt(value: any): number {
    try {
      if (value) {
        if (typeof value === 'string') {
          value = value.trim().replace(/ /g, '');

          if (value?.length) {
            let num = parseInt(value);

            if (!Number.isNaN(num) && typeof num === 'number') {
              return num;
            }
          }
        }
      }

      return 0;
    } catch (error) {
      return 0;
    }
  }

  public static toConstObject<T>(value: Object): T {
    try {
      const obj = {};

      for (const key in value) {
        Object.defineProperty(obj, key, {
          value: value[key],
          writable: false,
          enumerable: false,
          configurable: false,
        });
      }

      return obj as T;
    } catch (error) {
      return null;
    }
  }

  public static dateUTCToLocal(date: Date | string): Date {
    try {
      if (typeof date === 'string') date = new Date(date);

      let offset = date.getTimezoneOffset();
      let calced = date.getTime() - offset * 60000;
      let newDate = new Date(calced);

      return newDate;
    } catch (error) {
      return null;
    }
  }

  public static urlParamsToObject(url: string): any {
    try {
      if (url?.length) {
        url = url.replace('%20', '+');

        let segments = url.split('&');
        let result = {};

        segments.forEach((segment) => {
          let field = segment.split('=');
          result[field[0]] = field[1];
        });

        return result;
      }

      return null;
    } catch (error) {
      return null;
    }
  }

  public static toFlatArray<T>(array: Array<T>): Array<T> {
    try {
      return array.reduce(
        (accumulator, value) => accumulator.concat(value),
        []
      );
    } catch (error) {
      return null;
    }
  }

  public static toDate(date: Date | string): Date {
    if (typeof date === 'string') date = new Date(date);

    return new Date(date.toLocaleDateString());
  }

  private static _base64(value: string): string {
    return window.btoa(unescape(encodeURIComponent(value)));
  }

  private static _excelFormat(s: string, c: string): string {
    return s.replace(/{(\w+)}/g, (m, p) => {
      return c[p];
    });
  }

  public static tableToExcel(name: string, table: HTMLElement): void {
    const uri = 'data:application/vnd.ms-excel;base64,',
      template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body>${table.outerHTML}</body></html>`;
    const ctx = { worksheet: name || 'Worksheet', table: table.innerHTML };
    const encoding = `${uri}${window.btoa(
      template.replace(/{(\w+)}/g, (m, p) => ctx[p])
    )}`;

    DOMHelper.downloadFile(`${name}.xls`, encoding);
  }

  public static tableToExcel2(name: string, table: HTMLElement): void {
    let tableData = table.outerHTML;
    tableData = tableData.replace(/<A[^>]*>|<\/A>/g, '');
    tableData = tableData.replace(/<input[^>]*>|<\/input>/gi, '');

    DOMHelper.downloadFile(
      `${name}.xls`,
      `data:application/vnd.ms-excel, ${encodeURIComponent(tableData)}`
    );
  }

  public static tableToExcel3 = (
    tables: Array<HTMLTableElement>,
    wsnames: Array<string>,
    wbname: string,
    appname: string
  ) => {
    const uri = 'data:application/vnd.ms-excel;base64,',
      tmplWorkbookXML = `<?xml version="1.0"?><?mso-application progid="Excel.Sheet"?>
      <Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet">
        <DocumentProperties xmlns="urn:schemas-microsoft-com:office:office">
          <Author>Axel Richter</Author>
          <Created>{created}</Created>
        </DocumentProperties>
        <Styles>
          <Style ss:ID="Currency">
            <NumberFormat ss:Format="Currency"></NumberFormat>
          </Style>
          <Style ss:ID="Date">
            <NumberFormat ss:Format="Medium Date"></NumberFormat>
          </Style>
        </Styles>
        {worksheets}
      </Workbook>`,
      tmplWorksheetXML = `
      <Worksheet ss:Name="{nameWS}">
        <Table>{rows}</Table>
      </Worksheet>`,
      tmplCellXML = `
      <Cell{attributeStyleID}{attributeFormula}>
        <Data ss:Type="{nameType}">{data}</Data>
      </Cell>`;

    let ctx: any;
    let workbookXML: any;
    let worksheetsXML: string = '';
    let rowsXML: string = '';

    for (let i = 0; i < tables.length; i++) {
      // if (!tables[i].nodeType) tables[i] = document.getElementById(tables[i]);
      for (let j = 0; j < tables[i].rows.length; j++) {
        rowsXML += '<Row>';
        for (let k = 0; k < tables[i].rows[j].cells.length; k++) {
          let dataType = tables[i].rows[j].cells[k].getAttribute('data-type');
          let dataStyle = tables[i].rows[j].cells[k].getAttribute('data-style');
          let dataValue = tables[i].rows[j].cells[k].getAttribute('data-value');
          dataValue = dataValue
            ? dataValue
            : tables[i].rows[j].cells[k].innerHTML;
          let dataFormula =
            tables[i].rows[j].cells[k].getAttribute('data-formula');
          dataFormula = dataFormula
            ? dataFormula
            : appname == 'Calc' && dataType == 'DateTime'
            ? dataValue
            : null;
          ctx = {
            attributeStyleID:
              dataStyle == 'Currency' || dataStyle == 'Date'
                ? ' ss:StyleID="' + dataStyle + '"'
                : '',
            nameType:
              dataType == 'Number' ||
              dataType == 'DateTime' ||
              dataType == 'Boolean' ||
              dataType == 'Error'
                ? dataType
                : 'String',
            data: dataFormula ? '' : dataValue,
            attributeFormula: dataFormula
              ? ' ss:Formula="' + dataFormula + '"'
              : '',
          };
          rowsXML += Convert._excelFormat(tmplCellXML, ctx);
        }
        rowsXML += '</Row>';
      }
      ctx = { rows: rowsXML, nameWS: wsnames[i] || 'Sheet' + i };
      worksheetsXML += Convert._excelFormat(tmplWorksheetXML, ctx);
      rowsXML = '';
    }

    ctx = { created: new Date().getTime(), worksheets: worksheetsXML };
    workbookXML = Convert._excelFormat(tmplWorkbookXML, ctx);
    console.log(workbookXML);
    const link = document.createElement('a');
    link.href = uri + Convert._base64(workbookXML);
    link.download = wbname || 'Workbook.xls';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
}
