<div #fsFormField class="fs-form-field" [class]="options.classes" [ngClass]="{'field-events': !readOnly}">
    <div class="field-content">
        <div *ngIf="readOnly" class="readonly-field"></div>

        <div class="fs-field-wrapper">
            <ng-content></ng-content>
        </div>

        <div *ngIf="!hideActions && innerText?.length && !inputEl?.readOnly" class="action-field">
            <button *ngIf="inputType === 'text'" type="button" class="clear-button" (click)="onClearText()"></button>
            <button *ngIf="inputType === 'password'" type="button" class="change-visibility-pass"
                [ngStyle]="{'background-image': inputEl.type === 'password' ? 'url(./assets/icons/Visibility-ON-24.svg)' : 'url(./assets/icons/Visibility-OFF-24.svg)'}"
                (click)="onVisibilityPassword()"></button>
        </div>

        <div *ngIf="inputType === 'date'" class="action-field">
            <button type="button" class="date-picker" (click)="onClickDate()"></button>
        </div>

        <ng-content select="[fs-form-field-actions]"></ng-content>
        <a *ngIf="activeClickable" class="mask-link"></a>
    </div>
    <div class="input-info" *ngIf="!hideCounter">
        <fs-text-counter [limited]="maxLength" [text]="inputValue"></fs-text-counter>
    </div>
</div>