import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[fs-focus]',
})
export class FsFocusDirective implements AfterViewInit {
  @Input('autofocus') autofocus: boolean;

  constructor(private element: ElementRef<HTMLElement | any>) {
    this.autofocus = true;
  }

  ngAfterViewInit(): void {
    if (this.autofocus) (<HTMLElement>this.element.nativeElement)?.focus();
  }
}
