import { ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core';
import { FsPopupOptions } from '../fs-popups-shared';
import { FsPopupOverlayComponent } from './fs-popup-overlay.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FsPopupOverlayService {
  private _viewRef: ViewContainerRef;
  public get viewRef(): ViewContainerRef {
    return this._viewRef;
  }
  public set viewRef(v: ViewContainerRef) {
    this._viewRef = v;
  }

  constructor() {
    this._viewRef = null;
  }

  private _insertPopupOverlay(
    component: ComponentRef<FsPopupOverlayComponent>
  ): void {
    const fsOverlay = document.querySelector('body > .fs-overlay');

    if (fsOverlay) {
      document.body.insertBefore(component.location.nativeElement, fsOverlay);
    } else {
      document.body.appendChild(component.location.nativeElement);
    }
  }

  public raise<C>(popup: Type<C>, options?: FsPopupOptions): Observable<any> {
    try {
      if (!popup) throw new Error('No component defined');
      if (!this._viewRef) throw new Error('No viewRef defined');

      const overlay = this._viewRef.createComponent(FsPopupOverlayComponent);
      overlay.instance.options = options;
      overlay.instance.popup = popup;
      overlay.instance.close.subscribe((_) => overlay.destroy());
      overlay.instance.initialOverlay();
      this._insertPopupOverlay(overlay);

      return overlay.instance.close;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
