import { ValidationHelper } from './../helpers/validation-helper';
import { Injectable } from '@angular/core';
import { Subject, lastValueFrom } from 'rxjs';
import { UserResponse } from '../models/user.model';
import { GetEventCreateResourcesResult } from '../models/event.model';
import { LocationResponse } from '../models/location.model';
import { HttpService } from './http.service';
import { ApiUrl } from '../enums/api-url.enum';
import { VsResult } from '../enums/vs-result.enum';
import { CountryCodeService } from './country-code.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private userSub = new Subject<UserResponse>();
  public userSub$ = this.userSub.asObservable();

  private resourceSub = new Subject<GetEventCreateResourcesResult>();
  public resourceSub$ = this.resourceSub.asObservable();

  private _user: UserResponse;
  public get user(): UserResponse {
    return this._user;
  }
  public set user(v: UserResponse) {
    this._user = new UserResponse({ ...v });
    this.initialLocation();
    this.userSub.next(v);
  }

  private _locationId: number;
  public get locationId(): number {
    return this._locationId;
  }
  public set locationId(v: number) {
    this._locationId = v;
    this.initialLocation();
    this.saveLocationIdInLocal();
  }

  private _resources: GetEventCreateResourcesResult;
  public get resources(): GetEventCreateResourcesResult {
    return this._resources;
  }
  public set resources(v: GetEventCreateResourcesResult) {
    this._resources = v;
    this.resourceSub.next(v);
  }

  private _currentLocation: LocationResponse;
  public get currentLocation(): LocationResponse {
    return this._currentLocation;
  }
  public set currentLocation(v: LocationResponse) {
    this._currentLocation = v;
  }

  private _imageProfileName: string;
  public get imageProfileName(): string {
    return this._imageProfileName;
  }
  public set imageProfileName(v: string) {
    this._imageProfileName = v;
  }

  constructor(
    private httpService: HttpService,
    private countryCodeService: CountryCodeService
  ) {
    this._user = new UserResponse({});
    this._locationId = 0;

    this.fetchLocationIdFromLocal();
    this.initialLocation();
  }

  public initialLocation(): void {
    if (this._user.company?.locations?.length) {
      if (this._locationId > 0) {
        this._currentLocation = this._user.company.locations.find(
          (f) => f.id === this._locationId
        );
      } else {
        this._currentLocation = this._user.company.locations[0];
        this._locationId = this._currentLocation.id;
      }
    }
  }

  public async loadResources(): Promise<GetEventCreateResourcesResult> {
    try {
      this._resources = await lastValueFrom(
        this.httpService.getItems<GetEventCreateResourcesResult>(
          ApiUrl.FromLeague,
          '/admin/event-manager/create-resources'
        )
      );

      this._resources.courses?.forEach((course) => {
        course.teeBoxYardages?.sort((a, b) => {
          if (a.teePositionId > b.teePositionId) return 1;
          else if (b.teePositionId > a.teePositionId) return -1;
          else return 0;
        });
      });

      await this.countryCodeService.loadAsync();

      return this._resources;
    } catch (error) {
      console.error(error);
      return null as any;
    }
  }

  public getVsResult(value: VsResult): string {
    switch (value) {
      case VsResult.Loss:
        return 'Loss';
      case VsResult.Win:
        return 'Win';
      case VsResult.Tie:
        return 'Tie';
      default:
        return '';
    }
  }

  private saveLocationIdInLocal(): void {
    window.localStorage.setItem('_fs_location_id', this._locationId.toString());
  }

  private fetchLocationIdFromLocal(): void {
    let locId = +window.localStorage.getItem('_fs_location_id');
    if (ValidationHelper.fullCheckNumber(locId)) this._locationId = locId;
  }

  public getLocationById(id: number): LocationResponse {
    return this._user.company.locations?.find((f) => f.id === id);
  }
}
