<div class="fs-toast-container">
    <div *ngFor="let toast of toastService.toasts" class="fs-toast">
        <div class="fs-toast-desc">
            <div class="fs-toast-title">
                <h3>{{ toast.title }}</h3>
            </div>
            <div class="fs-toast-text">
                <p>{{ toast.description }}</p>
            </div>
        </div>
        <div class="fs-toast-action">
            <button type="button" fs-focus (click)="toastService.remove(toast.id)">X</button>
        </div>
    </div>
</div>