import { HttpParams } from '@angular/common/http';

export const sleep = (delay: number) =>
  new Promise((resolve) => setTimeout(resolve, delay));

export const intialValueInterval = async (
  callbackValue: () => {},
  delay: number = 100
): Promise<any> => {
  while (typeof callbackValue() === 'undefined' || callbackValue() === null)
    await sleep(delay);

  return callbackValue();
};

export const buildQueryParams = (obj: Object): HttpParams => {
  let queryParams = new HttpParams();
  for (const key in obj) queryParams = queryParams.append(key, obj[key]);
  return queryParams;
};

export const removeStorageItemsByExclude = (excludeKeys?: string[]): void => {
  let index = 0;

  while (true) {
    let key = window.localStorage.key(index);

    if (key?.length) {
      if (excludeKeys?.length) {
        let keyIndex = excludeKeys.findIndex((f) => f === key);

        if (keyIndex !== -1) {
          excludeKeys.slice(keyIndex, 1);
          index++;
          continue;
        }
      }

      window.localStorage.removeItem(key);
      index++;
    } else return;
  }
};

export const getUrlFragment = (): string => {
  let hash = window.location.hash;

  if (hash?.length) return window.location.hash?.substring(1, hash.length);
  return '';
};

export const manualCount = (
  dir: 'up' | 'down',
  value: number,
  min: number = 0,
  max: number = 100
): number => {
  switch (dir) {
    case 'up':
      if (value < max) return ++value;
      return value;
    case 'down':
      if (value > min) return --value;
      return value;
    default:
      return value;
  }
};

export const divisibleCalc = (
  value: number,
  unit: number,
  incremental: boolean = false,
  min: number = 0,
  max: number = 100
): number => {
  let result: number = value;

  while (result % unit !== 0) {
    if ((incremental && result > max) || (!incremental && result < min))
      return result;

    incremental ? result++ : result--;
  }
  return result;
};