import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FsToastComponent } from './fs-toast.component';
import { FsToastService } from './fs-toast.service';
import { FsFocusDirective } from '../../fs-directives/fs-focus.directive';

@NgModule({
  declarations: [FsToastComponent],
  imports: [CommonModule, FsFocusDirective],
  exports: [FsToastComponent],
  providers: [FsToastService],
})
export class FsToastModule {}
