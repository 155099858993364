import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/pages/auth/auth.service';
import { NavigationService } from './navigation.service';
import { FSMiniLoaderService } from 'src/app/shared/modules/fs-mini-loader/fs-mini-loader.service';
import { NavigationEnd, Router } from '@angular/router';
import { NavbarItemsData } from 'src/app/shared/static-objects';
import { HelperService } from 'src/app/shared/services/helper.service';
import { GlobalService } from 'src/app/shared/services/global.service';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, AfterViewInit {
  isActiveProfileBody: boolean;

  constructor(
    public globalService: GlobalService,
    public authService: AuthService,
    public navService: NavigationService,
    public helperService: HelperService,
    private loaderService: FSMiniLoaderService,
    private router: Router
  ) {
    this.navService.navRouteToggle = false;
    this.isActiveProfileBody = false;
    this.navService.navItems = NavbarItemsData;
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.urlAfterRedirects?.length) {
          let urlSplited = val.urlAfterRedirects.split('/');

          if (urlSplited?.length && urlSplited[1]) {
            this.navService.setNavItemActivate(urlSplited[1]);
          }
        }
      }
    });
  }

  ngOnInit(): void {
    this.navService.setNavItemActivate(this.navService.currentRoute);
    this.navService.initialNavItemActive();
  }

  onExpandProfile(): void {
    this.isActiveProfileBody = !this.isActiveProfileBody;
  }

  onSettings(): void {
    window.location.href = '/settings';
  }

  onExit(): void {
    this.loaderService.loader = true;
    this.onExpandProfile();
    this.authService.unautorized().then((_) => {
      window.location.reload();
    });
  }
}
