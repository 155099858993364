import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FsPageFrameComponent } from './fs-page-frame.component';
import { FsFormFieldModule } from '../fs-controls/fs-form-field/fs-form-field.module';
import { FsInputModule } from '../fs-controls/fs-input/fs-input.module';
import { FsResizeObserverDirective } from '../fs-directives/fs-resize.directive';

@NgModule({
  declarations: [FsPageFrameComponent],
  imports: [
    CommonModule,
    FsFormFieldModule,
    FsInputModule,
    FsResizeObserverDirective,
  ],
  exports: [FsPageFrameComponent],
})
export class FsPageFrameModule {}
