const awsmobile = {
  aws_project_region: "us-west-2",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_QXkqgwCpu",
  aws_user_pools_web_client_id: "3v4ldn7o506f68hit0m65nhv4n",
  oauth: {
    domain: "bay-manager.auth.us-west-2.amazoncognito.com",
    scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
    // "redirectSignIn": "https://localhost:401",
    // "redirectSignOut": "https://localhost:401/login",
    redirectSignIn: "https://devbaymanager.fsgapi.net/login/",
    redirectSignOut: "https://devbaymanager.fsgapi.net/login",
    responseType: "code",
  },
};

export default awsmobile;
