import { TemplateRef } from '@angular/core';
import { FsButtonAppearance } from './fs-controls/fs-button/fs-button.model';

export class SelectItem<T = any> {
  id?: number;
  index?: number;
  name?: string;
  value?: any;
  classes?: string;
  hasCustom?: boolean;
  template?: TemplateRef<any>;
  isActive?: boolean;
  extraOptions?: T;
  childs?: SelectItem[];

  constructor(model: SelectItem) {
    this.id = model.id || 0;
    this.index = model.index || 0;
    this.name = model.name || '';
    this.value = model.value;
    this.classes = model.classes || '';
    this.hasCustom = model.hasCustom || false;
    this.template = model.template || (null as any);
    this.isActive = model.isActive || true;
    this.extraOptions = model.extraOptions || (null as T);
    this.childs = model.childs || (null as any);
  }
}

export enum FullDirection {
  Top = 1,
  Right = 2,
  Bottom = 3,
  Left = 4,
}

export enum VerticalDirection {
  Top = 1,
  Bottom = 2,
}

export enum HorizontalDirection {
  Left = 1,
  Right = 2,
}

export class PriorityArrayItem<T> {
  fieldName?: string;
  value?: T;
  direction?: VerticalDirection;

  constructor(model: PriorityArrayItem<T>) {
    this.fieldName = model.fieldName || '';
    this.value = model.value;
    this.direction = model.direction || VerticalDirection.Top;
  }
}

export enum FSActionElementType {
  Link = 1,
  Button = 2,
}

export enum FsColorPalette {
  Primary = 1,
  Success = 2,
  Warning = 3,
  Danger = 4,
  Black = 5,
  LightGray = 6,
  White = 7,
}

export declare type FsOutlineStyle = 'Clear' | 'Circular' | 'Sharp' | 'Round';

export declare type FsColorPaletteSymbols =
  | 'primary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'black'
  | 'light-gray'
  | 'dark-gray'
  | 'white'
  | '';

export declare type ElementSize = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export declare type FsWidthSymbols =
  | 'unset'
  | 'inherit'
  | 'max-content'
  | 'min-content'
  | string;

export declare type FsMessageTypeSymbols =
  | 'Success'
  | 'Warning'
  | 'Error'
  | 'Info'
  | 'Normal';

export declare type FsScreenOrientation = 'portrait' | 'landscape' | 'none';

export declare type FsWhenOccur = 'before' | 'after';

export class FSActionElement<V> {
  id?: number;
  appearance?: FsButtonAppearance;
  color?: FsColorPaletteSymbols;
  type?: FSActionElementType;
  name?: string;
  value?: V;
  role?: string;
  classes?: string;
  isActive?: boolean;
  focused?: boolean;
  action?: () => {};

  constructor(model: FSActionElement<V>) {
    this.id = model.id || 0;
    this.appearance = model.appearance || 'default';
    this.type = model.type || FSActionElementType.Button;
    this.color = model.color || 'white';
    this.name = model.name || '';
    this.value = model.value || (null as any);
    this.role = model.role || '';
    this.classes = model.classes || '';
    this.isActive = model.isActive || true;
    this.focused = model.focused || false;
    this.action = model.action || (null as any);
  }
}

export enum InlinePosition {
  Start = 1,
  Center = 2,
  End = 3,
}

export enum AlignPosition {
  Default = 0,
  InlineStart = 1,
  InlineCenter = 2,
  InlineEnd = 3,
  BlockStart = 4,
  BlockCenter = 5,
  BlockEnd = 6,
}

export interface IBoundingClientRect {
  bottom?: number;
  height?: number;
  left?: number;
  right?: number;
  top?: number;
  width?: number;
  x?: number;
  y?: number;
}

export class ElementEvent<V> {
  event?: any;
  value?: V;

  constructor(model: ElementEvent<V>) {
    this.event = model.event || (null as any);
    this.value = model.value || (null as any);
  }
}

export class FsOverlayOptions {
  parentEl: HTMLElement;
  contentRef: HTMLElement;
  contentItemsCount: number;
  maxItemsPerView?: number;
  leftOffset?: number;
  topOffset?: number;
  width?: number;

  constructor(model: FsOverlayOptions) {
    this.parentEl = model.parentEl;
    this.contentRef = model.contentRef;
    this.contentItemsCount = model.contentItemsCount;
    this.maxItemsPerView = model.maxItemsPerView || 8;
    this.leftOffset = model.leftOffset || 0;
    this.topOffset = model.topOffset || 0;
    this.width = model.width || 0;
  }
}

export class FsOverlayContentOptions {
  parentRect: IBoundingClientRect;
  contentRect: IBoundingClientRect;
  parentOptions: FsOverlayOptions;
  overlayContainer: HTMLElement;

  constructor(model: FsOverlayContentOptions) {
    this.parentRect = model.parentRect;
    this.contentRect = model.contentRect;
    this.parentOptions = model.parentOptions;
    this.overlayContainer = model.overlayContainer;
  }
}

export enum SortDirection {
  Default = 0,
  ASC = 1,
  DESC = 2,
}

export class SortOption {
  direction?: SortDirection;
  field?: string;

  constructor(model: SortOption) {
    this.direction = model.direction || SortDirection.ASC;
    this.field = model.field || '';
  }
}

export enum ControlType {
  Unknown = 0,
  Button = 1,
  Select = 2,
  InputText = 3,
  InputNumber = 4,
}

export enum TransferElementMethod {
  Default = 0,
  UpToDown = 1,
  DownToUp = 2,
  LeftToRight = 3,
  RightToLeft = 4,
}

/**
 * قسمت اول ارتفاع رو میگه. قسمت دوم عرض
 * مثلا StartStart یعنی مبدا شروع ارتفاع و مبدا شروع عرض
 */
export enum AnchorElementPosition {
  StartStart = 1,
  StartCenter = 2,
  StartEnd = 3,
  CenterStart = 4,
  CenterCenter = 5,
  CenterEnd = 6,
  EndStart = 7,
  EndCenter = 8,
  EndEnd = 9,
}

export class Point {
  x?: number;
  y?: number;

  constructor(model: Point) {
    this.x = model.x || 0;
    this.y = model.y || 0;
  }
}

export enum InnerElementsLayoutDirection {
  Block = 1,
  Inline = 2,
}

export class DOMRectFull {
  //DOMRect fields
  readonly bottom: number;
  readonly height: number;
  readonly left: number;
  readonly right: number;
  readonly top: number;
  readonly width: number;
  readonly x: number;
  readonly y: number;

  //Custom fields
  readonly rightScreen: number;
  readonly bottomScreen: number;
  readonly centerELementTop: number;
  readonly centerELementBottom: number;
  readonly centerELementLeft: number;
  readonly centerELementRight: number;

  constructor(element: HTMLElement) {
    const rect = element.getBoundingClientRect();
    const sWidth = window.innerWidth,
      sHeight = window.innerHeight;

    this.bottom = rect.bottom;
    this.height = rect.height;
    this.left = rect.left;
    this.right = rect.right;
    this.top = rect.top;
    this.width = rect.width;
    this.x = rect.x;
    this.y = rect.y;

    this.rightScreen = sWidth - this.right;
    this.bottomScreen = sHeight - this.bottom;

    this.centerELementTop = this.height / 2 + this.top;
    this.centerELementBottom = this.height / 2 + this.bottomScreen;
    this.centerELementLeft = this.width / 2 + this.left;
    this.centerELementRight = this.width / 2 + this.rightScreen;
  }
}
