export class RandomGenerator {
  public static boolean(): boolean {
    return Math.round(Math.random() * 10) % 2 === 0;
  }

  public static number(
    digits: number = 100,
    inNegRange: boolean = false
  ): number {
    const num = Math.round(Math.random() * digits);

    if (num === 0) return num;

    if (inNegRange) {
      return RandomGenerator.boolean() ? num : num * -1;
    }

    return num;
  }

  public static betweenTwoNumber(min: number = 0, max: number = 100): number {
    const minCeiled = Math.ceil(min),
      maxFloored = Math.floor(max);

    return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled);
  }

  public static inList<T>(list: Array<T>): T {
    if (!list?.length) return null;
    return list[RandomGenerator.betweenTwoNumber(0, list.length)];
  }

  public static divisibleNumber(
    target: number,
    min: number = 0,
    max: number = 100
  ): number {
    if (target < min || target > max) return 0;

    let value = RandomGenerator.betweenTwoNumber(min, max);

    if (value > target) {
      while (value % target !== 0) value--;
      return value;
    } else if (value < target) {
      while (value % target !== 0) value++;
      return value;
    } else {
      return value;
    }
  }

  public static stringId(
    length: number = 5,
    characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  ): string {
    const charactersLength = characters.length;
    let result = '',
      counter = 0;

    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    return result;
  }
}
