<main>
    <div class="main" [class]="pageFrameService.backgroundClass">
        <navigation></navigation>

        <div class="container">
            <fs-page-frame>
                <router-outlet *ngIf="authService.isAuthenticate !== authState.False"></router-outlet>
            </fs-page-frame>
        </div>
    </div>
</main>

<fs-mini-loader></fs-mini-loader>
<fs-toast></fs-toast>

<a class="ticket-link" href="https://fullswinggolf.my.site.com/support/s/fscompeteadmin" target="_blank" title="Help">
    <i class="helper-question ico x36"></i>
</a>