import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { PageFrameService } from './fs-page-frame.service';
import { FSMiniLoaderService } from '../fs-mini-loader/fs-mini-loader.service';

@Component({
  selector: 'fs-page-frame',
  templateUrl: './fs-page-frame.component.html',
  styleUrls: ['./fs-page-frame.component.scss'],
})
export class FsPageFrameComponent implements OnInit, AfterViewInit {
  @ViewChild('frameContentRef') frameContentRef: ElementRef<HTMLElement>;
  @ViewChild('frameHeaderRef') frameHeaderRef: ElementRef<HTMLElement>;
  @ViewChild('frameBodyRef') frameBodyRef: ElementRef<HTMLElement>;

  constructor(
    public pageFrameService: PageFrameService,
    public loaderService: FSMiniLoaderService
  ) {}

  resized(event: DOMRectReadOnly): void {
    if (
      this.frameContentRef?.nativeElement &&
      this.frameHeaderRef?.nativeElement &&
      this.frameBodyRef?.nativeElement
    ) {
      this.pageFrameService.contentEl = this.frameContentRef.nativeElement;
      this.pageFrameService.headerEl = this.frameHeaderRef.nativeElement;
      this.pageFrameService.bodyEl = this.frameBodyRef.nativeElement;

      this.pageFrameService.checkFrameSize();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.pageFrameService.frameEl = this.frameContentRef.nativeElement;
    });
  }

  ngOnInit(): void {}

  onSearch(event: any): void {
    this.pageFrameService.setSearchInput(event.target.value);
  }

  isShowHeader(): boolean {
    return (
      this.pageFrameService.isActiveSearchBox ||
      this.pageFrameService.isActiveHistoryState
    );
  }
}
