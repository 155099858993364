export const $ = (selector: string) => {
  return document.querySelectorAll(selector);
};

export class DOMHelper {
  public static scrollTo(top: number = 0, left: number = 0): void {
    window.scrollTo({
      top,
      left,
      behavior: 'smooth',
    });
  }

  public static isActionalFormElement(tagName: string): boolean {
    if (!tagName?.length) return false;

    tagName = tagName.toLowerCase();

    if (
      tagName === 'button' ||
      tagName === 'input' ||
      tagName === 'textarea' ||
      tagName === 'select'
    ) {
      return true;
    }

    return false;
  }

  public static getElementIndexFromParent(el: HTMLElement): number {
    try {
      let parent = el.parentElement;
      return Array.from(
        parent.querySelectorAll(el.tagName.toLowerCase())
      )?.findIndex((f) => f === el);
    } catch (error) {
      return -1;
    }
  }

  public static downloadFile = (name: string, url: string): void => {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = name;
    a.click();
  };

  public static classGroupReplacement(
    el: HTMLElement,
    oldTokens: string[],
    newToken: string
  ): void {
    if (el?.classList.length && oldTokens?.length)
      oldTokens.forEach((token) => {
        if (el.classList.contains(token)) {
          newToken?.length
            ? el.classList.replace(token, newToken)
            : el.classList.remove(token);
        }
      });
  }
}
