<div class="frame" resizeObserver [ngClass]="{'frameless': !pageFrameService.useFrameWrapper}"
    (resized)="resized($event)">
    <div #frameContentRef class="frame-content">
        <div #frameHeaderRef *ngIf="pageFrameService.useFrameWrapper && isShowHeader()" class="frame-header">
            <svg width="100%" height="100%" viewBox="0 0 1227 50" fill="none" xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMinYMin meet">
                <path
                    d="M0 4C0 1.79086 1.79086 0 4 0H448.808C449.913 0 450.968 0.456675 451.724 1.2618L458.94 8.9461C459.696 9.75122 460.751 10.2079 461.856 10.2079H765.144C766.248 10.2079 767.304 9.75122 768.06 8.9461L775.276 1.2618C776.032 0.456675 777.087 0 778.192 0H1196.51C1197.62 0 1198.67 0.456675 1199.43 1.2618L1225.92 29.4692C1226.61 30.2109 1227 31.19 1227 32.2074V50H0V4Z"
                    fill="black" [attr.fill-opacity]="pageFrameService.backgroundOpacity" />
            </svg>

            <div class="utils">
                <div class="start-slot">
                    <div *ngIf="pageFrameService.isActiveHistoryState && pageFrameService.getCurrentHistory() && pageFrameService.getCurrentHistory().type !== 0"
                        class="history-state" [ngClass]="{'readonly-control': loaderService.loader}">
                        <a [class]="pageFrameService.historyIndex > 1 ? 'cursor-pointer' : 'readonly-control'"
                            (click)="pageFrameService.popHistory()">
                            <span *ngIf="pageFrameService.historyIndex > 1"><i></i></span>
                            {{ pageFrameService.getCurrentHistory().title }}
                        </a>
                    </div>
                    <ng-container [ngTemplateOutlet]="pageFrameService.startSlotTemplate"></ng-container>
                </div>

                <div *ngIf="pageFrameService.isActiveSearchBox" class="frame-search-box">
                    <img src="./assets/icons/Magnifier.svg" alt="search">
                    <fs-form-field [hideActions]="true" class="black-bg-input"
                        [options]="{classes: 'frame-search-field'}">
                        <input id="search" type="text" (keyup)="onSearch($event)">
                    </fs-form-field>
                </div>

                <div class="end-slot">
                    <ng-container [ngTemplateOutlet]="pageFrameService.endSlotTemplate"></ng-container>
                </div>
            </div>
        </div>

        <div #frameBodyRef [ngClass]="{'frameless': !pageFrameService.useFrameWrapper}"
            [style.backgroundColor]="'rgba(0, 0, 0, ' + pageFrameService.backgroundOpacity + ')'" class="frame-body">
            <ng-content></ng-content>
        </div>
    </div>
</div>